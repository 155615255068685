.immo-area {
	.immo-box {
		padding: 15px;
		background-color: #ffffff;
		border: 1px solid #c3c6cc;
		text-align: center;
		margin-bottom: 30px;

		@media (min-width:991px) {
			min-height: 600px;
		}
	}

	.immo-box-content {
		padding: 30px 15px 15px;

		.headline {
			font-size: 18px;
			margin: 0;
			font-weight: 300;
		}

		p {
			padding-top: 15px;
		}
	}

	.immo-image {
		overflow: hidden;
		position: relative;

		&:after {
			content: 'verkauft';
			text-transform: uppercase;
			font-weight: 400;
			position: absolute;
			top: 20%;
			left: -30%;
			background-color: #a5d6d1;
			display: block;
			width: 100%;
			color: #3c3c3c;
			padding-top: 2px;
			-webkit-transform: rotate(-30deg);
			-moz-transform: rotate(-30deg);
			-ms-transform: rotate(-30deg);
			transform: rotate(-30deg);
		}

		img {
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			-o-transform: scale(1);
			transform: scale(1);
			-webkit-transition: all 0.3s linear;
			-moz-transition: all 0.3s linear;
			-ms-transition: all 0.3s linear;
			-o-transition: all 0.3s linear;
			transition: all 0.3s linear;

			&:hover {
				-webkit-transform: scale(1.2);
				-moz-transform: scale(1.2);
				-ms-transform: scale(1.2);
				-o-transform: scale(1.2);
				transform: scale(1.2);
			}
		}
	}
}