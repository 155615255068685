.blogcontent {
	max-width: 980px;
	margin: auto;
}

.blog-units {

	clear: both;

	.grid {

		padding-top: 60px;

		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		gap: 15px;

		/*	@media(max-width: 991px) {
			grid-template-columns: 1fr 1fr;
		} */

		@media(max-width: 580px) {
			grid-template-columns: 1fr;
		}

	}

	.blog-unit-container {
		padding: 10px;
		border: 1px solid #c3c6cc;
		border-radius: 5px;
		width: 100%
	}

	.blog-unit {

		.img-col {
			aspect-ratio: 16/9;
			position: relative;

			img {
				border-radius: 5px;
			}

			.plus-object {
				position: absolute;
				top: 0px;
				right: 10px;

				img {
					width: 55px;
					border-radius: 0px;
				}
			}

			.search-result-properties {
				display: block;

				li {
					font-size: 18px;
					margin-right: 5px;
					display: inline-block;
				}
			}

			.unit-feature-icons {
				position: absolute;
				top: 10px;
				width: 45px;
				left: 0;
				z-index: 2;

				i {
					background-image: url(RESOURCE/img/sprite-icon-unit-ft.png);
					background-repeat: no-repeat;
					background-position: center;
					background-color: var(--skinny);
					height: 35px;
					width: 45px;
					margin-bottom: 5px;
					border-radius: 0 5px 5px 0;
					display: inline-flex;

					&:before {
						content: "";
					}

					&.fa-paw {
						&.cross {
							background-position: -105px 0;
						}
					}

					&.fa-nosmoke {
						background-position: 2px 0;
					}

					&.fa-smoke {
						background-image: url(RESOURCE/img/icon-smoke.png);
					}
				}
			}
		}




		.unit-content {

			.unit-title {
				margin-top: 10px;
				text-transform: uppercase;
				font-family: var(--font-heading);
				font-weight: 600;
				font-size: 15px;
				padding-top: 5px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}

			.rating2 {
				height: 20px;
				margin-top: 5px;

				>.ratings {
					display: inline-block;
					margin-right: -4px;
				}

				span:last-child {
					display: block;
				}


				>div {
					align-items: flex-start;
					justify-content: flex-start;
					display: flex;
				}


				.fa {
					width: 15px;
					height: 15px;
					border-radius: 50%;
					display: inline-block;
					background-color: var(--primary);
					border: 2px solid var(--primary);

					&:before {
						content: "";
					}

					&.fa-star-half-o {
						background: rgb(97, 132, 122);
						background: linear-gradient(90deg, rgba(97, 132, 122, 1) 51%, rgba(255, 255, 255, 1) 50%);
					}

					&.fa-star-half-alt {
						background: rgb(97, 132, 122);
						background: linear-gradient(90deg, rgba(97, 132, 122, 1) 50%, rgba(255, 255, 255, 1) 50%);
					}

					&.fa-star-o {
						background-color: transparent;
					}
				}

				span {
					font-size: 14px;
					color: var(--green);
					display: inline-block;
					position: relative;
					top: -2px;
					margin-left: 5px;
				}
			}

			.unit-address {
				margin-top: 5px;
				font-size: 14px;
			}

			.properties {
				display: flex;
				margin-top: 10px;
				font-size: 14px;
			}

			.column {
				flex: 1;
			}
		}

		.button-line {
			margin-top: 10px;
		}

	}
}