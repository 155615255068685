.booking-view {
	@media (max-width:992px) {
		margin-top: 15px;
	}

	padding-top: 155px !important;

	.page-header {
		margin-top: 0;
	}

	.form-group {
		.form-control {
			padding-left: 10px;
		}

		select.form-control {
			padding-left: 5px;
		}
	}

	.cart-details {
		padding: 14px;
		background-color: var(--grey3);
		margin-top: 60px;

		.price-table {
			.total {
				td {
					font-size: 16px;
					color: var(--secondary);
				}
			}

			td {
				.btn {
					display: none !important;
				}
			}

			.price {
				color: var(--primary) !Important;
			}
		}

		@media (max-width:992px) {
			margin-top: 30px;
		}

		.unit-details {
			margin: 20px 0;
			color: var(--black2);

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;
				font-size: 15px;

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--primary);
				}
			}
		}

		.price {
			color: var(--primary);
			text-align: right;
			font-weight: bold;
		}
	}

	.pd-form {


		label {
			font-weight: 500;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


}