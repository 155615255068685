@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.search-main {


	.attention {

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0px;
			width: 100%;
			height: 5px;
			background-color: red;

		}

	}


	.unit-price-rat {
		margin-bottom: 30px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		width: 100%;
		min-height: 72px;

		.alternatives {
			padding-top: 20px;

			.altheading {
				font-weight: 700;
				font-size: var(--font-size-md);
			}

			a {
				font-size: var(--font-size-md);
				text-decoration: none;
				color: var(--color-secondary);

				&:hover {
					text-decoration: underline;
				}
			}

			.invalid {
				text-decoration: line-through;
			}

			.special {
				color: red;
				font-weight: 700;
			}

			.fa {
				font-size: var(--font-size-sm);
				color: var(--color-secondary);
			}
		}
	}

	.heading-sect-bl {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			margin-top: 15px;
		}

		.heading-subtxt {
			width: 50%;

			@media (max-width: 1199px) {
				max-width: 100%;
			}

			@media (max-width: 767px) {
				max-width: 100%;
				width: 100%;
				font-size: 25px;
			}

			small {
				@media (max-width: 767px) {
					font-size: 14px;
				}
			}
		}

		.alert-info {
			max-width: 400px;
			width: 100%;
			margin: 0;
			margin-bottom: 0;

			@media (max-width: 1199px) {
				max-width: 100%;
			}
		}
	}

	.list-map-tabs {
		padding: 20px 0 30px 0;

		.container {
			@media (max-width: 992px) {
				max-width: 100%;
				width: 100%;
			}
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid var(--primary);

			li.active {
				a {
					background: var(--primary);
					color: #fff;
				}
			}

			li {
				max-width: 130px;
				width: 33%;

				a {
					color: var(--black2);
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					padding: 15px 0 12px 0;
					line-height: 15px;
					cursor: pointer;

					&:hover,
					&:focus {
						background: var(--primary);
						color: #fff;
					}


				}
			}
		}
	}

	.list-map-tabs-top {
		padding: 20px 0 0 0;

		.container {
			@media (max-width: 992px) {
				max-width: 100%;
				width: 100%;
			}
		}

		h3 {
			margin-bottom: 20px;

			@media (max-width: 767px) {

				width: 100%;
			}
		}

		.result-header {
			.form-group {
				margin-bottom: 10px;
			}

			.srt-bl-top {
				justify-content: space-between;
				align-items: unset;

				@media (max-width: 767px) {
					justify-content: unset;
				}
			}
		}
	}

	.search-tocken {
		padding: 0 0 0 0;
		border-bottom: 0px solid #ebebeb;
	}
}

.search-offer-info-v1 {
	background-color: #fff;
	padding: 10px;
	border: 1px solid #ff0000;
	border-radius: 5px;

	span {
		color: var(--black);
		font-size: 15px;

		&.offer-value {
			color: red;
		}
	}

}


.search-form {


	.layout-row {
		.container {
			display: flex;

			@media (max-width:992px) {
				width: 100%;
			}

			.map-pane {
				width: 100%;
				height: 750px;
				padding-left: 15px;
				margin-top: 15px;

				@media (max-width: 767px) {
					height: 530px;
				}
			}
		}

		&.map-view {
			.map-pane {
				padding-left: 0;

				.map-main {

					height: calc(100% - 75px);

					@media (max-width: 767px) {
						height: calc(100% - 135px);
					}

					@media all and (min-width: 768px) and (max-width: 991px) {
						height: calc(100% - 100px);
					}

					.flex {
						height: 100%;

						>div {
							height: 100%;
						}

						.mapnote {
							padding: 0 0 20px 0;
							font-size: 16px;

							@media (max-width: 767px) {
								min-height: 135px;
							}

							@media all and (min-width: 768px) and (max-width: 991px) {
								min-height: 90px;
							}
						}
					}

					&.no-map {
						height: 100%;

						.mapconsent {
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							background: rgba(26, 121, 187, .15);
							flex-direction: column;
							padding: 0 15px;

							p {
								margin: 0 0 20px 0
							}

							a {
								background: var(--primary);
								color: #fff;
								border-radius: 5px;
								padding: 2px 15px;
								height: 36px;
								display: block;
								line-height: 35px;

								&:hover {
									background: var(--secondary);
								}
							}
						}
					}
				}
			}
		}
	}

	.two-col {
		.col-sm-6 {
			&:nth-child(2n-1) {
				clear: both;
			}
		}
	}

	.sort-price {
		max-width: 230px;
		width: 100%;
	}

	.search-form-bl {


		.advance-search-btn {
			text-decoration: underline;
			position: relative;

			.fa {

				margin: 0 0 0 5px;
				font-size: 18px;
				line-height: 27px;
				position: absolute;
				top: -2px;
				right: -17px;

			}
		}

		.container {
			background-color: var(--grey4);
			padding: 15px;
			border-radius: 10px;
		}

		.headline small {
			font-size: 18px;
		}



		.form-group {
			>label {
				font-weight: 300;
				text-transform: uppercase;
				color: var(--green);
			}

			.datepicker-trigger {
				display: block;
			}

			#region-trigger {
				background-color: var(--white);
			}

			.form-bx-area {
				position: relative;

				i {
					background-color: var(--green);
					width: 34px;
					border-radius: 0 5px 5px 0;
					color: #c3c6cc;
					position: absolute;
					right: 0;
					text-align: center;
					height: 34px;
					top: 0;
					line-height: 34px;
					pointer-events: none;
				}

				.form-control {
					padding: 7px 15px;
					border: 1px solid #c3c6cc;
					border-radius: 4px;
					height: 34px;
					text-align: left;
				}

				select.form-control {
					padding: 5px 10px;
				}

			}
		}

		.form-group2 {
			>label {
				font-weight: 300;
				text-transform: uppercase;
				/*color: var(--white);*/
				color: var(--black2);
			}

			.datepicker-trigger {
				display: block;
			}

			#region-trigger {
				background-color: var(--white);
			}

			.form-bx-area {
				position: relative;

				i {
					background-color: var(--green);
					width: 34px;
					border-radius: 0 5px 5px 0;
					color: #c3c6cc;
					position: absolute;
					right: 0;
					text-align: center;
					height: 34px;
					top: 0;
					line-height: 34px;
					pointer-events: none;
				}

				.form-control {
					padding: 7px 15px;
					border: 1px solid #c3c6cc;
					border-radius: 4px;
					height: 34px;
					text-align: left;
				}

				select.form-control {
					padding: 5px 10px;
				}

			}
		}

		.collapse-toggle {
			&:before {
				content: "\f068";
				margin-right: 4px;
				font-family: 'FontAwesome';
			}

			&.collapsed {
				&:before {
					content: "\f067";
				}
			}
		}



		.label-li {
			label {
				color: var(--black2:);
			}

			margin-top: 10px;
			margin-bottom: 10px;
		}

		.form-btn {
			margin-top: 24px;

			.btn-primary {
				padding: 9px 16px;

			}
		}
	}
}


.sidebar-search {


	.widgt-title {
		text-transform: uppercase;
		margin-bottom: 10px;
		display: block;
		color: var(--black);
		font-weight: 600;
		position: relative;

		a {
			float: right;
			text-transform: none;
			font-weight: 500;
			position: absolute;
			right: 0;
			width: auto;
			top: 0;
		}
	}

	.label-li {
		li {
			margin-bottom: 8px;

			.checkbox {
				margin: 0;
				font-size: 13px;
				color: var(--black5);
			}
		}
	}

	.form-control {
		margin-bottom: 10px;
		width: 100%;
		text-align: left !important;
	}
}

.available-dates {
	padding: 0 25px;
	margin-bottom: 15px;

	.owl-dt-bl {
		text-align: center;

		.dt-box {
			font-size: 13px;
			color: var(--white);
			letter-spacing: 1px;
			font-weight: bold;
			background-color: var(--secondary);
			padding: 5px;
			text-transform: uppercase;
		}

		.dt-cont-bl {
			padding: 30px 10px;
			background-color: var(--white);

			h3 {
				font-size: 19px;
				margin-bottom: 0;
				font-weight: 600;
			}
		}
	}

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		button {
			position: absolute;
			left: 0;
			color: #a4a4a4;

			span {
				font-size: 28px !important;
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;
			}
		}
	}
}

.search-about-bl {
	background-color: var(--white);
	margin-bottom: 25px;

	.srch-bl-title {
		border-bottom: 1px solid #E5E5E5;
		padding: 10px 15px;
		text-transform: uppercase;
		font-weight: 600;
		margin: 0;
	}

	.srch-ab-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 15px;
		flex-wrap: wrap;
	}

	.search-rest-left {
		h3 {
			font-size: 25px;
			font-weight: 400;
			margin: 0 0 5px;
		}

		span {
			font-size: 15px;
			color: var(--darkgrey);
			margin-bottom: 5px;
		}
	}
}


.results {
	>.row {
		margin: 0;
	}

	.single-unit {

		.unit-img {
			position: relative;

			.new-un-tag {
				position: absolute;
				bottom: 15px;
				left: 0;
				font-size: 18px;
				background: #a5d6d1;
				z-index: 5;
				padding: 8px 15px;
				color: var(--black2);
				border-radius: 0 5px 5px 0;
				text-transform: uppercase;

				span {
					font-family: var(--font-heading);

					i {
						font-size: 15.5px;
					}
				}
			}
		}

		.map-marker {
			position: absolute;
			right: 3px;
			top: 6px;
			z-index: 2;
			color: var(--white);
			width: 30px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 20px;
		}

		.objnr {
			position: absolute;
			right: 15px;
			top: 0px;
			z-index: 2;
			background-color: var(--primary);
			color: var(--white);
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			padding: 5px 15px;
			border-radius: 5px;
			font-weight: 700;
		}

		.btn-primary {
			margin-top: 10px;
		}
	}
}


.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		padding: 3px 0;
		/* margin: 0 -15px; */

		color: #565a5c;


		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin: 15px 0;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

		.main-price {
			text-decoration: line-through;
		}

		.red {
			color: #FF0000;
		}

	}

	.map-view {
		.result-pane {
			display: none;
		}
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;


		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {}

	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
			color: red;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.unit-cont-bl {
			margin-bottom: 15px;

			.alternatives {
				padding: 12px 0 0;
				position: relative;
				display: block;
				color: var(--black);

				.li-bullet {
					list-style: disc;
					padding-left: 18px;
					margin-bottom: 0;
					margin-left: 15px;
				}
			}

			.offer-box {
				position: relative;
				display: flex;
				margin: 0;
				align-items: center;
				color: var(--black);
				margin-bottom: 10px;

				@media (max-width:767px) {
					display: block
				}

				.popover {
					border-radius: 0;
					background-color: var(--grey2);
					padding: 10px;
					border: 1px solid #C0C0C0;
					font-size: 12px;
					box-shadow: inherit;
					width: 100%;
					max-width: 520px;

					.popover-title {
						border-radius: 0;
						background-color: transparent;
						margin-bottom: 10px;
						padding: 0 0 4px;
						border-bottom: 1px solid #C0C0C0;
						font-size: 12px;
					}

					.arrow {
						&:after {
							border-top-color: #C0C0C0 !important;
						}
					}

					.popover-content {
						padding: 0;


						ul {
							overflow-y: scroll;
							height: 180px;

							li {
								position: relative;
								margin-bottom: 2px;
								padding-left: 14px;
								list-style: none;
								font-size: 12px;
								color: #47403a;
								font-family: var(--dfont);
								margin-bottom: 3px;

								&:before {
									background-color: var(--primary);
									width: 5px;
									height: 5px;
									position: absolute;
									content: "";
									left: 0;
									top: 6px;
									border-radius: 50%;

								}
							}
						}
					}
				}



				.offer-left {
					background-image: url(RESOURCE/img/bg-offer-text2.png);
					background-repeat: no-repeat;
					background-position: center;
					text-transform: uppercase;
					width: 260px;
					height: 34px;
					margin-bottom: 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					color: var(--black);
					font-weight: bold;

					@media (max-width:767px) {
						display: block;
						text-align: center;
						margin-right: 0;
						margin-bottom: 15px;
						line-height: 34px;
					}
				}

				ul {
					padding: 0;
					list-style: none;
					margin: 0;

					li {
						margin: 0 0 5px;
						line-height: 14px;
						font-size: 14px
					}
				}
			}
		}

	}


	.search-result-properties {
		display: block;

		li {
			font-size: 18px;
			margin-right: 5px;
			display: inline-block;
		}
	}

	.sort-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		.sort-by {

			display: flex;
			align-items: center;

			>.box {
				border: none !important;

				.arrow {
					z-index: 2;
				}

				>.body {
					padding: 0;

					.list-group {
						margin-bottom: 0;
					}
				}
			}

			strong {
				font-size: 14px;
				color: var(--grey3);
				margin-right: 15px;
			}

			.sort-item {
				color: var(--grey3);
				font-size: 12px;
				margin: 5px 0;
				background-color: var(--white);
				padding: 10px 12px;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				display: inline-block;
			}

		}

		.sort-rest {
			color: var(--grey3);
			font-weight: 600;
		}
	}





	.search-bar {
		padding-top: 15px;
		padding-bottom: 20px;

		@media (max-width: 767px) {
			padding-top: 0;
		}


		.my-search-token {
			font-size: 12px;
			border-radius: inherit;
			background-color: var(--secondary);
			border: none;
			padding: 3px 6px;

			.close {
				color: var(--white) !important;
				opacity: 1;
				padding: 0 !important;
				text-shadow: none;
				font-size: 16px;
				position: relative !important;
				left: 2px;
				top: 1px;
				display: inline-block !important;
			}
		}

		&.srch-rest {
			.widt-title {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 1px;
				margin-top: 0;
			}

			.rest-count {
				color: #a3a3a3;
				font-size: 13px;
				display: block;
				margin-bottom: 5px;
			}

			.text-link {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
			}
		}


		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {
			/* width: 790px; */
			max-width: 100%;

			.more-btn-panel {
				display: none;
			}


		}


		.results {

			.unit-row {
				@mixin flexlayout row;

				.info-col {
					width: 46%;
					padding: 0 0 0 15px;
				}

				.unit-price-col {
					width: 22%;
					margin: -15px;
					margin-left: 0;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}



	}



	.toggle-button {
		display: none;

		@media (max-width:992px) {
			position: fixed;
			top: 50vh;
			display: block;
			background-color: var(--secondary);
			padding: 8px 15px;
			z-index: 50;
			left: 0;
			color: var(--white);
			font-size: 24px;
		}
	}

	.sidebar-search {
		@media (min-width:993px) {
			display: block !important;
		}


	}
}

#searchpanel {
	.container {
		width: auto;
	}

	@media (min-width:993px) {
		position: relative;
		display: block;
		overflow: visible;

		.close {
			display: none;
		}

		&.modal {
			z-index: 3 !important;
		}

		&.fade {
			opacity: 1;
		}

		.modal-dialog {
			width: auto;
			-webkit-transform: translateY(0%);
			transform: translateY(0%);
		}
	}





	@media (max-width:992px) {
		.search-bar {
			border: 1px solid #eee;
			border-top: none;
		}

		&.modal.fade {
			.modal-dialog {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
				position: fixed !important;
			}

			&.in {
				.modal-dialog {
					-webkit-transform: translateX(0);
					transform: translateX(0);
				}
			}
		}
	}

	.modal-dialog {
		@media (max-width:992px) {
			margin: 0;
			padding: 50px 15px 15px;
			z-index: 20001;
			max-width: 370px;
			width: 100%;
			background-color: var(--white);
			overflow-y: scroll;
			height: 100vh;

			>.close {
				color: var(--fff);
				padding: 0 8px 1px;
				position: absolute;
				top: 6px;
				right: 16px;
				z-index: 50;
				opacity: 1;
				font-size: 36px;
				background-color: #ff9a00;
				background-color: var(--primary);
				line-height: 1;
			}
		}
	}
}

.toggle-button2 {
	border-radius: 30px;
	margin-bottom: 30px;
	padding: 15px 30px 15px 30px;
	max-width: 238px;
	width: 100%;

}

.karten-suche {
	border: 1px solid var(--primary);
}

.hidesort {
	display: none;
}