.main-footer-area {
	.bg-footer {
		/* background-image: url(RESOURCE/img/bg-footer-neu.jpg); 
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;*/
		position: relative;
		padding: 75px 0 120px;

		&:before {
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.5);
			left: 0;
			top: 0;
			position: absolute;
			content: "";
			z-index: 1;
		}

		.bg-footer-img {
			width: 100%;
			height: 314px;
			object-fit: cover;
			position: absolute;
			top: 0;

			@media (max-width: 400px) {
				height: 403px;

			}

			@media all and (min-width: 401px) and (max-width: 767px) {
				height: 385px;
			}
		}

		.container {
			position: relative;
			z-index: 2;

			@media (max-width: 400px) {
				max-width: 375px;
				width: 100%;
			}

			@media all and (min-width: 401px) and (max-width: 767px) {
				max-width: 400px;
				width: 100%;
			}

			.headline {
				h2 {
					@media (max-width: 375px) {
						padding-left: 20px;
						padding-right: 20px;
					}
				}

			}
		}
	}



	.footer-cont {
		position: relative;
		top: -50px;
		z-index: 1;

		.icon-logo {
			display: inline-block;
			position: relative;
			top: -34px;
		}

		a {
			color: var(--black2);
			text-decoration: none;

			&:hover {
				color: var(--green);
			}
		}

		p {
			color: var(--black2);
			line-height: 1.5;
			font-size: 13px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.social-bl {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #000;

			a {
				width: 40px;
				height: 40px;
				border-radius: 5px;
				font-size: 20px;
				background-color: var(--black2);
				color: var(--white);
				margin: 0 5px;
				display: inline-block;
				line-height: 44px;
				position: relative;

				&:hover {
					background-color: var(--primary);

					span {
						color: var(--primary);
					}
				}

				span {
					font-size: 16px;
					color: var(--white);
					position: absolute;
					left: 50px;
				}
			}

			a.text {
				width: auto;
				background: transparent;
				color: var(--black2);

				&:hover {
					color: var(--primary);
				}
			}
		}

		.headline-bl {
			color: var(--white);

			span {
				color: var(--brown-light);
			}
		}

		p {
			color: var(--light-blue);

			a {
				color: var(--light-blue);
			}
		}
	}

	.newsletter-bl {
		background-color: var(--grey);
		padding: 30px 0;

		.newslet-flex-row {
			@media (min-width:993px) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			text-align:center;

			.newst-title {
				position: relative;
				padding-left: 50px;

				@media (max-width:992px) {
					padding-left: 0;
					margin-bottom: 10px;
				}

				i {
					height: 40px;
					width: 40px;
					text-align: center;
					line-height: 40px;
					background-color: #61847a;
					border-radius: 50%;
					border: 1px solid var(--white);
					-webkit-box-shadow: 0 0 0 2px #61847a;
					box-shadow: 0 0 0 2px #61847a;

					left: 0;
					font-size: 22px;
					color: var(--white);

					@media (min-width:993px) {
						position: absolute;
					}
				}

				h3 {
					color: var(--white);
					font-size: 24px;
					margin: 0;
					text-align: left;

					@media (max-width:992px) {
						text-align: center;
					}
				}

				p {
					color: var(--white);
					font-size: 24px;
					margin: 0;
					text-align: left;

					@media (max-width:992px) {
						text-align: center;
					}
				}

				span {
					color: var(--white);
				}
			}

			.newslet-form-bl {

				@media (min-width:993px) {
					display: flex;
				}

				.form-control {
					padding: 6px 12px;
					font-size: 14px;
					line-height: 1.42857;
					border-radius: 4px;
					color: var(--green);
					background-color: var(--white);
					background-image: none;
					border: 1px solid #c3c6cc;
					width: 214px;
					margin-right: 3px;

					@media (max-width:992px) {
						width: 100%;
						margin-bottom: 5px;
					}
				}
			}
		}
	}

	.footer-black {
		background-color: #2e2d2c;
		color: var(--white);
		font-size: 18px;
		padding-top: 25px;
		min-height: 270px;
		padding-bottom: 10px;

		@media (max-width: 767px) {
			padding-left: 15px;
			padding-right: 15px;
		}

		.footer-col {
			@media (max-width: 767px) {
				padding-bottom: 0;
				margin-bottom: 20px;
				border-bottom: 1px solid rgb(255 255 255 / 20%);
			}

			&:last-child {
				@media (max-width: 767px) {
					border-bottom: 0px solid rgb(255 255 255 / 20%);
				}
			}
		}

		ul {
			li {
				margin: 0 0 5px 0;

				@media (max-width: 991px) {
					text-align: center;
				}

				a {
					color: #fff;
					font-weight: 300;

					&:hover {
						color: var(--primary2);
					}
				}
			}
		}

		p {
			font-size: 22px;
			font-weight: 500;
			margin: 0 0 20px 0;

			@media (max-width: 991px) {
				text-align: center;
			}
		}

		h4,
		.h4 {
			font-family: var(--bodyfont);
			color: var(--color-white);
			margin: 55px 0 20px;
			text-transform: capitalize;
			text-align: center;
			font-size: 18px;

			@media (max-width: 991px) {
				text-align: center;
				margin-top: 15px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				margin-top: 85px;
			}
		}

		.card-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 100%;
			margin: 0px auto;



			li.first {
				img {
					width: auto;
					height: 30px;

					@media all and (min-width: 992px) and (max-width: 1199px) {
						height: 25px;
					}
				}
			}

			li {
				width: auto;
				margin-bottom: 20px;
				margin-left: 7px;
				margin-right: 7px;

				@media all and (min-width: 992px) and (max-width: 1199px) {
					margin-left: 5px;
					margin-right: 5px;
				}

				img {
					width: auto;
					margin: 0 auto;
					height: 20px;

					@media all and (min-width: 992px) and (max-width: 1199px) {
						height: 20px;
					}

				}
			}

		}

		.ft-nav {
			li {
				list-style: none;
				display: inline-block;
				position: relative;

				&:before {
					border-left: 1px solid #9d9d9c;
					content: "";
					height: 15px;
					position: absolute;
					left: -2px;
					top: 4px;
				}

				&:first-child {
					&:before {
						display: none;
					}

					a {
						margin-left: 0;
					}
				}

				a {
					margin: 0 10px;
					font-size: 16px;
					color: var(--white);
					text-transform: capitalize;

					&:hover {
						color: var(--primary);
					}
				}
			}
		}

		.footer-comp-dt {
			text-transform: uppercase;
			color: #fff;
			color: var(--white);
			position: relative;
			max-width: 270px;
			width: 100%;
			margin: 0 auto;
			text-align: center;
			float: right;
			padding-top: 65px;

			@media (max-width: 991px) {
				max-width: 330px;
				margin: 15px auto 50px;
				width: 100%;
				float: none;
			}

			span {
				font-family: var(--font-heading);

				&.tel-row1 {
					font-size: 55px;
					line-height: 40px;

					@media all and (min-width: 992px) and (max-width: 1199px) {
						font-size: 45px;
						line-height: 30px;
					}
				}

				&.tel-row2 {
					font-size: 22px;

					@media all and (min-width: 992px) and (max-width: 1199px) {
						font-size: 18px;
					}
				}

				&.tel-row3 {
					font-size: 26px;
					display: block;
					margin-top: 12px;

					@media all and (min-width: 992px) and (max-width: 1199px) {
						font-size: 22px;
					}
				}
			}



			i.icon-call {


				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				opacity: .1;
				background-color: #fff;
				color: #2e2d2c;
				border-radius: 50%;
				width: 250px;
				height: 250px;
				text-align: center;
				line-height: 250px;
				font-size: 80px;

				@media (max-width: 767px) {
					float: none;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					width: 220px;
					height: 220px;
				}

				/* @media (max-width:992px) {
					display: none;
				} */
			}
		}
	}
}