.last-minute-unit {

	.single-unit {
		.unit-img {
			position: relative;

			.new-un-tag {
				position: absolute;
				top: unset;
				bottom: 15px;
				left: 0;
				font-size: 18px;
				background: #a5d6d1;
				z-index: 5;
				padding: 8px 15px;
				color: var(--black2);
				border-radius: 0 5px 5px 0;
				text-transform: uppercase;

				span {
					font-family: var(--font-heading);

					i {
						font-size: 15.5px;
					}
				}
			}
		}

		.objnr {
			position: absolute;
			right: 15px;
			top: 0px;
			z-index: 2;
			background-color: var(--primary);
			color: var(--white);
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			padding: 5px 15px;
			border-radius: 5px;
			font-weight: 700;
		}

		.btn-primary {
			margin-top: 10px;
		}

		.unit-price-rat {
			margin-bottom: 30px;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			width: 100%;

			.alternatives {
				padding-top: 20px;

				.altheading {
					font-weight: 700;
					font-size: var(--font-size-md);
				}

				a {
					font-size: var(--font-size-md);
					text-decoration: none;
					color: var(--color-secondary);

					&:hover {
						text-decoration: underline;
					}
				}

				.invalid {
					text-decoration: line-through;
				}

				.special {
					color: red;
					font-weight: 700;
				}

				.fa {
					font-size: var(--font-size-sm);
					color: var(--color-secondary);
				}
			}
		}



	}

	/* .single-unit {
		width: 100%;
		padding: 0;
		margin: 0 auto 30px;
		transition: all 0.25s ease 0s;
		position: relative;
		border-top: 2px solid #c3c6cc;
		border-bottom: 2px solid #c3c6cc;
		background-color: var(--grey2);
		padding-top: 2px;

		&:hover {
			background-color: var(--grey3);

			.unit-img {
				img {
					transform: scale(1.1);
				}
			}
		}

		@media (max-width:499px) {
			max-width: 340px;
		}

		@media (max-width:374px) {
			max-width: 290px;
		}

		.objnr {
			position: absolute;
			right: 15px;
			top: 0px;
			z-index: 2;
			background-color: var(--primary);
			color: var(--white);
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			padding: 5px 15px;
			border-radius: 5px;
			font-weight: 700;
		}

		.unit-img {
			position: relative;

			.new-tag {
				position: absolute;
				bottom: 15px;
				left: 0;
				font-size: 18px;
				background: #a5d6d1;
				z-index: 5;
				padding: 8px 15px;
				color: var(--black2);
				border-radius: 0 5px 5px 0;
				text-transform: uppercase;

				span {
					font-family: var(--font-heading);

					i {
						font-size: 15.5px;
					}
				}
			}
		}

		.unit-cont-bl {
			padding: 10px 15px 15px;

			.unit-title {
				color: var(--black2);
				font-size: 18px;
				margin: 0;
				padding-bottom: 8px;
				text-transform: uppercase;
				min-height: 55px;

				&:hover {
					color: var(--primary);
				}
			}

			.unit-address {
				font-size: 16px;
				color: var(--green);
				font-weight: 400;
				margin-bottom: 15px;

				i {
					display: inline-block;
					margin-right: 5px;
				}
			}


			.unit-feature-icons {
				position: absolute;
				top: 20px;
				width: 45px;
				left: 0;
				z-index: 2;

				i {
					background-image: url(RESOURCE/img/sprite-icon-unit-ft.png);
					background-repeat: no-repeat;
					background-position: center;
					background-color: var(--skinny);
					height: 35px;
					width: 45px;
					margin-bottom: 5px;
					border-radius: 0 5px 5px 0;
					display: inline-flex;

					&:before {
						content: "";
					}

					&.fa-paw {
						&.cross {
							background-position: -105px 0;
						}
					}

					&.fa-nosmoke {
						background-position: 2px 0;
					}

					&.fa-smoke {
						background-image: url(RESOURCE/img/icon-smoke.png);
					}
				}
			}

			>.ratings {
				display: flex;
				justify-content: space-between;
				margin-bottom: 15px;
				min-height: 46px;

				flex-wrap: wrap;

				.rat-icon-bl {
					display: block;

					.fa-star {
						color: var(--primary);
						font-size: 18px;

						&.fa-star-half {
							background: #c3c6cc;
							background: -moz-linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
							background: -webkit-linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
							background: -o-linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
							background: linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
						}
					}
				}

				.rating2 {

					>.ratings {
						display: inline-block;
						margin-right: -4px;
					}

					span:last-child {
						display: block;
					}


					>div {
						align-items: flex-end;
						justify-content: flex-end;
						display: flex;
					}

					.fa {
						width: 15px;
						height: 15px;
						border-radius: 50%;
						display: inline-block;
						background-color: var(--primary);
						border: 2px solid var(--primary);

						&:before {
							content: "";
						}

						&.fa-star-half-o {
							background: rgb(97, 132, 122);
							background: linear-gradient(90deg, rgba(97, 132, 122, 1) 51%, rgba(255, 255, 255, 1) 50%);
						}

						&.fa-star-half-alt {
							background: rgb(97, 132, 122);
							background: linear-gradient(90deg, rgba(97, 132, 122, 1) 50%, rgba(255, 255, 255, 1) 50%);
						}

						&.fa-star-o {
							background-color: transparent;
						}
					}

					span {
						font-size: 16px;
						color: var(--green);
						display: inline-block;
						position: relative;
						top: -2px;
						margin-left: 5px;
					}
				}
			}




			.unit-link {
				text-transform: uppercase;
				color: var(--primary);
				font-size: 12px;
				font-weight: bold;
				position: relative;
				top: 9px;

				&:hover {
					color: var(--secondary);
				}
			}

			.unit-properties-li {
				padding-left: 15px;
				min-height: 100px;

				li {
					display: block;
					color: var(--green);
				}
			}

			.unit-rating {
				font-size: 14px;
				min-height: 22px;
				display: block;

				.stars {
					display: inline-block;
				}

				i {
					color: var(--green);
				}

				span {
					color: var(--black);
				}
			}

			.alternatives {
				padding-top: 20px;

				.altheading {
					font-weight: 700;
					font-size: var(--font-size-md);
				}

				a {
					font-size: var(--font-size-md);
					text-decoration: none;
					color: var(--color-secondary);

					&:hover {
						text-decoration: underline;
					}
				}

				.invalid {
					text-decoration: line-through;
				}

				.special {
					color: red;
					font-weight: 700;
				}

				.fa {
					font-size: var(--font-size-sm);
					color: var(--color-secondary);
				}
			}
		}
	} */
}