.index-action-modal {

	/* Modal-Hintergrund */
	.index-modal {
		display: none;
		/* Standardmäßig versteckt */
		position: fixed;
		z-index: 900;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		align-items: center;
		justify-content: center;
	}

	/* Modal-Box */
	.index-modal-content {
		background: white;
		padding: 20px;
		border-radius: 10px;
		width: 90%;
		max-width: 400px;
		text-align: center;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		position: relative;

		h2 {
			padding: 0px 10px;
		}

		.lead {
			margin-bottom: 10px;
		}

		.btn-line {
			margin-top: 15px;
		}
	}

	/* Schließen-Button */
	.index-close {
		position: absolute;
		top: 10px;
		right: 15px;
		font-size: 24px;
		cursor: pointer;
	}
}