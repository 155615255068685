.eigentuemer {

	.object-fit {
		object-fit: cover;
		height: auto;
		width: 100%;
	}


	.content-wrapper {
		padding-bottom: 20px;
		/* Abstand zum Video */
	}

	.video-container {
		width: 100%;
	}

	.only-1200 {
		display: none;

		@media(min-width: 1200px) {
			display: block;
		}
	}

	.mt-sm-20 {
		@media(max-width: 991px) {
			margin-top: 20px;
		}
	}

	.mt-xs-20 {
		@media(max-width: 767px) {
			margin-top: 20px;
		}
	}

	.mt-20 {
		margin-top: 20px;
	}

	.mt-30 {
		margin-top: 30px;
	}

	.pt-20 {
		padding-top: 20px;
	}

	.pt-30 {
		padding-top: 30px;
	}

	.only-1199 {
		display: none;

		@media(max-width: 1199px) {
			display: block;
		}
	}

	.video {
		width: 100%;
		height: auto;

		@media(min-width: 1200px) {
			height: 248px;
			width: 439px;
		}

		@media(min-width: 1400px) {
			height: 313px;
			width: 554px;
		}

	}

	.flex-50 {
		@media (min-width:768px) {
			display: flex;
			align-items: center;
			gap: 20px;

		}
	}

	.d-flex-50 {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 20px;

	}

	.half {
		flex: 1;
	}

	.btn {
		border-radius: 16px;
		padding: 8px 37px;
	}

	.form-control {
		padding: 7px 5px 7px 5px;
	}

	.team-block-eig {
		display: block;
		margin-bottom: 20px;
		line-height: 1.42857;
		background-color: #fff;
		border-radius: 4px;

		@media (max-width: 767px) {

			max-width: none;
			margin: 0;

		}

		.caption {
			padding: 9px 0px;
			color: #888e99;
		}
	}

	.eig-hightlights {
		padding: 30px 0px;

		.eig-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 50px;

			@media(max-width:991px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media(max-width:620px) {
				grid-template-columns: repeat(1, 1fr);
			}

			.item {
				text-align: center;

				&.advantage-hidden {
					display: none;
				}

				.icons {
					img {
						height: 80px;
						width: 80px;
					}
				}

				.title {
					text-transform: uppercase;
					min-height: 42px;
					font-weight: 600;
					margin-top: 20px;
					font-size: 15px;
					font-family: 'Hind', sans-serif;
					color: #7b818a;
				}

				.desc {}
			}
		}
	}

	.btn-line {
		text-align: center;
		padding-bottom: 40px;
		padding-top: 20px;
	}



}