.seasons2025 {
	.sectionOne {
		.flexOne {
			display: flex;

			img {
				margin: 10px;
			}
		}

	}
}

.inner-container {
	margin-top: 145px;

	.btn.btn-lg,
	.btn.btn-xlg {
		white-space: break-spaces;
	}

	.page-title {
		padding-bottom: 0px;
		margin-bottom: 10px;
	}

	.pad40 {
		padding: 40px 0px;
	}

	.body-text {
		p {
			color: var(--green);
		}
	}

	p {
		line-height: 1.8em;
	}

	.mt-30 {
		margin-top: 30px;
	}

	.padl25 {
		padding-left: 25px;
	}

	.mt-20-15 {
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.seasonoffers {
		.blog-block {
			.img-thumbnail {
				padding: 4px;
				line-height: 1.42857143;
				background-color: #fff;
				border: 1px solid #ddd;
				border-radius: 4px;
				-webkit-transition: all .2s ease-in-out;
				transition: all .2s ease-in-out;
				display: inline-block;
				max-width: 100%;
				width: 100%;
				max-height: 310px;
				object-fit: cover;
			}

		}
	}

	.seasonoffers .lt-bl-cont {
		background-color: #f3f3f3;

		h4 {
			min-height: 45px;
		}
	}

	.divider.small-gap {
		margin: 20px 0px;
	}

	.team-video {
		text-align: center;

		h2 {
			@media (max-width: 767px) {
				text-align: left;
			}
		}

		video {
			height: auto;
			width: 100%;
			max-width: 400px;
			margin-bottom: 10px;
		}
	}

	.team-img {
		img {
			max-width: 50%;
			width: 100%;
			margin: 0 auto 30px;

			@media (max-width: 767px) {
				max-width: 100%;
			}
		}
	}

	.team-block {
		display: block;
		padding: 4px;
		margin-bottom: 20px;
		line-height: 1.42857;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 4px;

		@media (max-width: 767px) {
			max-width: 440px;
			margin: 0 auto 20px;
		}

		img {
			width: 100%;
		}
	}

	.caption {
		padding: 9px;
		color: #888e99;
	}


	.mt-10 {
		margin-top: 10px;
	}

	.image-heading-bl {
		text-align: center;

		.heading-bl {
			text-align: left;

			h3 {
				font-size: 26px;
			}
		}

		.heading-bl-content {
			min-height: 180px;
			padding-top: 20px;

			@media (max-width:768px) {
				min-height: 130px;
			}
		}

		p {
			@media (max-width:768px) {
				min-height: 130px;
			}
		}

		.serv-img {
			margin-bottom: 5px;
			display: block;
			height: 185px;
			overflow: hidden;

			img {

				object-fit: cover;
				width: 100%;
				height: 100%;

			}
		}

		.btn {
			text-transform: lowercase;
		}

		margin:15px 0 15px;

	}


	.services-table {
		.table-striped {
			tr {
				td {
					border: none;

					@media (max-width:992px) {
						display: block;
						padding-top: 0;
					}
				}

				th {
					max-width: 285px;
					border-top: 0px !important;
					padding: 15px !important;

					@media (max-width:992px) {
						display: block;
						max-width: inherit;
						padding-bottom: 0;
					}
				}
			}
		}

	}

	.widgt-grey-bl {
		max-width: 640px;
		margin: 0 auto;

		.form-control {
			padding-left: 10px;
		}
	}

	.lt-bl-cont {
		.bl-title {
			color: var(--black2);

			&:hover {
				color: #626872;
			}
		}

		p {
			line-height: 1.4;
		}
	}


}

ul.list {
	padding: 0 0 0 20px;

	li {

		margin-bottom: 10px;
		position: relative;
		padding: 0 0 0 5px;

		&:before {
			content: "\f00c";
			font-family: FontAwesome;
			display: block;
			margin-left: -1.3em;
			width: 1.3em;
			color: var(--primary);
			position: absolute;
			left: 0px;
			top: 2px;
			font-size: 12px;
		}
	}
}

.Anreise-Sylt {
	.double-arrow-li {
		li {
			@media (max-width: 767px) {
				padding: 5px 0 5px 20px;
			}

			a {
				word-break: break-all;
			}

		}
	}
}

.staitcSearchBar {
	position: relative;
	margin-bottom: 30px;

	.container {
		padding: 0;
	}

	h2,
	.h2 {
		margin-bottom: 20px;

		@media (max-width: 767px) {
			padding-right: 80px;
			max-width: 300px;
		}
	}

	.best-price-bedge {
		position: absolute;
		top: -15px;
		right: 0;

		@media (max-width: 767px) {

			top: 50px;
		}

		img {
			width: 100px;

			@media (max-width: 767px) {
				width: 70px;
			}
		}
	}

	.search-form-area {
		background-color: #fff;
		box-shadow: 0 3px 15px 0 rgb(0 0 0 / 20%);
		padding: 25px;

		@media (min-width:993px) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 0;
			flex-flow: wrap;
			padding: 50px;
			position: relative;
		}

		>h3,
		.h3 {
			font-size: 22px;
			margin-bottom: 0;

			@media (max-width:992px) {
				font-size: 26px;
			}

			@media (max-width: 767px) {
				padding-right: 30px;
				max-width: 300px;
			}
		}

		.search-btm-text {

			padding: 12px 10px;
			color: var(--white);
			text-align: center;
			background-color: var(--primary);
			font-family: var(--font-heading);

			@media (min-width:993px) {
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				border-radius: 0 0 10px 10px;
			}

			@media (max-width:992px) {
				margin: 15px -15px 0;
			}

			a {
				font-weight: 700;
				color: var(--white);
				text-decoration: underline;

				&:hover {
					color: var(--black2);
				}
			}
		}

		.srh-form-right {
			@media (min-width:993px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.form-flex {
			margin: 2px;

			@media (max-width:992px) {
				width: 100%;
				padding: 6px 0;
			}

			.datepicker-trigger {
				width: 100%;
			}

			.form-bx-area {
				position: relative;

				&.slt-arrow {
					&:after {
						position: absolute;
						right: 9px;
						top: 15px;
						width: 0;
						height: 0;
						border-top: 4px dashed var(--primary);
						border-right: 4px solid transparent;
						border-left: 4px solid transparent;
						content: "";
					}
				}
			}

			.fa {
				position: absolute;
				left: 10px;
				top: 10px;
				color: #888e99;
				pointer-events: none;
			}


			.form-control {
				padding: 7px 5px 7px 32px;
				background-color: var(--white);
				border: none;
				border: 1px solid #c3c6cc;
				color: #888e99;
				border-radius: 5px;
			}

			>label {
				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}
			}
		}

		.form-btn {

			.btn {
				padding: 8px 12px;
				font-size: 14px;
			}
		}
	}

	s .searchbar {
		@media (min-width:993px) {
			position: absolute;
			width: 100%;
			bottom: 0;
			max-width: inherit !important;
			transform: translate(0, -50%);
		}

		@media (max-width:992px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.row {
			margin: 0;
		}

		.searchbox {

			.form-control {
				height: 40px;
			}

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}


		@media (min-width:993px) {
			max-width: 950px;
		}

	}


}

.blog-container {
	margin-bottom: 15px;


	strong {
		font-size: 26px;
		text-transform: uppercase;
		font-family: Bitter, serif;
		font-family: var(--font-heading);
		margin-top: 0;
		color: #3c3c3c;
		color: var(--black2);
		font-weight: 300;
		line-height: 1.2em;
		margin-bottom: 10px;
		display: inline-block;
	}

	ul {
		padding: 0 0 0 20px;

		li {
			margin-bottom: 10px;
			position: relative;
			padding: 0 0 0 5px;

			&:before {
				content: "\f00c";
				font-family: FontAwesome;
				display: block;
				margin-left: -1.3em;
				width: 1.3em;
				color: #61847a;
				color: var(--primary);
				position: absolute;
				left: 0;
				top: 2px;
				font-size: 12px;
			}
		}
	}


}

.block-tile {
	background: var(--dark-blue);
	padding: 30px;
	color: var(--white);
	margin-bottom: 30px;
	position: relative;

	.tile-icon {
		img {
			width: 100px;
		}

		&.logo-white {
			img {
				width: 150px;
			}
		}
	}

	h2 {
		color: var(--white);
		font-size: 20px;
	}

	.link {
		position: absolute;
		height: 100%;
		width: 100%;

		left: 0;
		top: 0;
	}
}

.sylterplus-v1 {
	.margin-top {
		margin-top: 100px;
	}

	.ratings {
		.ratings {
			.fa {
				background-color: var(--brown) !important;
				border: 2px solid var(--brown) !important;

				&.fa-star-half-o {

					background: linear-gradient(90deg, #d1b79e 51%, #1f393b 0) !important;
				}
			}
		}

	}

	h1,
	h2 {
		color: var(--white);
	}

	.search-form-bl {
		.container {
			background-color: var(--primary) !important;

			.advance-search-btn {
				color: var(--white);
			}

			.headline {
				color: var(--white);
			}

			.form-group {
				.form-bx-area {
					position: relative;

					i {
						background-color: var(--dark-blue) !important;

					}





				}
			}

			.form-group2 {
				>label {
					font-weight: 300;
					text-transform: uppercase;
					color: var(--white);
				}
			}
		}


	}

	.list-map-tabs-top {
		h3 {
			color: var(--white);
		}
	}

	.list-map-tabs {
		ul {
			li {
				a {
					color: var(--white) !important;
				}
			}
		}
	}

	.map-view {
		.result-pane {
			display: none;
		}
	}

	.map-unit-preview {
		a {
			color: var(--white);
		}

		.prices {
			color: var(--white);
		}
	}

	.sylt-er-block {
		top: 151px !important;

		@media (max-width:993px) {
			top: 122px !important;
		}

		@media (max-width:767px) {
			top: 147px !important;
		}

		@media (max-width:730px) {
			top: 140px !important;
		}

		@media (max-width:480px) {
			top: 147px !important;
		}
	}
}